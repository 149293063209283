@font-face {
    font-family: Heading;
    src: url(../assest/fonts/amsterdamthreeslant-axaym.ttf);
}

.kenya-landing-page {
    font-family: "Poppins", sans-serif;
}

.navbar-brand img {
    width: 80px;
}

.main-logo {
    display: flex;
    align-items: center;
}

.main-logo img {
    width: 130px;
    border-right: 1px solid #000;
    padding-right: 1rem;
}

.main-logo p {
    margin-left: 2rem;
    margin-bottom: 0;
    font-family: "Heading";
    font-size: 2rem;
    letter-spacing: 5px;
}

.navbar .btn-register {
    margin-left: -13rem;
}

.btn-register {
    background-color: #ffcc00;
    border: none;
    border-radius: 0.374rem;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: 13px 23px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
        transform 0.1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
}

.btn-register:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow 0.2s;
}

.btn-register:active {
    background-color: #f7f7f7;
    border-color: #000000;
    transform: scale(0.96);
}

.btn-register:disabled {
    border-color: #dddddd;
    color: #dddddd;
    cursor: not-allowed;
    opacity: 1;
}

/* banner */
.banner {
    height: 80vh;
    background-image: url("../assest/img/kenyalanding/banner/hong-kong-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-bg {
    background-color: rgba(255, 255, 255, 0.63);
}

.navbar-toggler {
    border: none;
}

.banner .banner-content {
    background: rgba(255, 255, 255, 0.847);
    /* height: 100%; */
    /* padding: 0% 25%; */
    padding: 1rem;
    width: fit-content;
    text-align: center;
    /* color: #fff; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;

    & h1 {
        font-size: 2.5rem;
        font-weight: 800;
    }

    & p {
        /* font-size: 1.3rem; */
        margin-bottom: 5px;
    }

    & img {
        width: 100px;
    }

    & a {
        text-decoration: none;
        color: #000;
    }

    & i {
        color: #ff0000;
    }
}

.banner .banner-content img {
    width: 200px;
    margin-bottom: 1rem;
}

.banner .banner-content strong {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: -5px;
    display: inline-block;
}

.banner .banner-content .event-time {
    transform: translateY(5px);
    /* display: inline-block; */
    background: #ffcc00;
    padding: 5px 10px;
    border-radius: 0.375rem;
}

@media (max-width: 768px) {
    .navbar-brand img {
        width: 50px;
    }

    .banner {
        height: 55vh;
    }

    .banner .banner-content {
        padding: 1rem 5px;
    }

    .banner .banner-content img {
        width: 160px;
        margin-bottom: 1rem;
    }

    .banner .banner-content h1 {
        font-size: 20px;
    }

    .banner .banner-content p {
        font-size: 14px;
    }

    .banner .banner-content .btn {
        font-size: 12px;
    }

    .banner .banner-content .btn:hover {
        background: #000;
    }

    .banner .banner-content .btn:hover a {
        color: #fff;
    }

    .banner .banner-content .btn a {
        color: #000;
    }
}

/* activities */
.card-constant {
    & h1 {
        font-size: 24px;
        font-weight: 600;
    }

    & p {
        font-size: 16px;
    }
}

/*  event section*/

.event-section {
    /* background: #c8eaea; */
    padding: 40px 0px;
    border: 1px solid #e3e3e3;
    border-radius: 0.375rem;
    /* min-height: 300px; */
    /* background: #e0e0e0; */
    /* box-shadow: 5px 5px 10px #bcbcbc,
          -5px -5px 10px #ffffff;
      background-image: linear-gradient(134deg, rgba(1, 1, 1, 0.02) 0%, rgba(1, 1, 1, 0.02) 16%, transparent 16%, transparent 76%, rgba(58, 58, 58, 0.02) 76%, rgba(58, 58, 58, 0.02) 100%), linear-gradient(215deg, rgba(166, 166, 166, 0.02) 0%, rgba(166, 166, 166, 0.02) 33%, transparent 33%, transparent 79%, rgba(111, 111, 111, 0.02) 79%, rgba(111, 111, 111, 0.02) 100%), linear-gradient(303deg, rgba(215, 215, 215, 0.02) 0%, rgba(215, 215, 215, 0.02) 7%, transparent 7%, transparent 90%, rgba(192, 192, 192, 0.02) 90%, rgba(192, 192, 192, 0.02) 100%), linear-gradient(302deg, rgba(113, 113, 113, 0.02) 0%, rgba(113, 113, 113, 0.02) 34%, transparent 34%, transparent 73%, rgba(65, 65, 65, 0.02) 73%, rgba(65, 65, 65, 0.02) 100%), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255)); */
}

.event-section a {
    text-decoration: none;
    color: #000;
}

.section-title {
    text-align: center;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    /* color: #d33223;
      background: -webkit-linear-gradient(135deg, #f4b936, #243058);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text; */
}

.section-title p {
    /*max-width: 645px;*/
    margin: 0 auto;
    font-size: 18px;
}

.section-title span {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #f4b835;
}

/* features-area */

.features-area .project-contents-msp {
    font-size: 1.2rem;
    font-weight: 600;
}

.features-area .container .row a {
    text-decoration: none;
}

/* .features-area .container .row .card {
      padding: 5%;
      border: none;
  
      & img {
          width: 100%;
          transition: 600ms;
  
          &:hover {
              opacity: 0.8;
              -webkit-transition: opacity 0.3s ease-in-out;
              transform: scale(1.06);
              object-fit: cover;
              -webkit-transition: all 0.8s ease-in-out;
          }
      }
  
      & .project-title-msp {
          margin-top: 10px;
          text-align: center;
          background-color: #333E63;
          line-height: 2em;
          border-radius: 5px;
          color: #fff;
      }
  } */

.single-features {
    /* margin-bottom: 1rem; */
    /* background-color: #ffffff; */
    text-align: center;
    /* -webkit-box-shadow: 0 0 1rem rgba(108, 118, 134, 0.1);
      box-shadow: 0 0 1rem rgba(108, 118, 134, 0.1); */
    /* -webkit-transition: 0.5s;
      transition: 0.5s; */
    border-radius: 0.375rem;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    overflow: hidden;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    /* border-radius: 28px; */
    /* background: #e0e0e0; */
    /* box-shadow: 5px 5px 10px #bcbcbc,
          -5px -5px 10px #ffffff; */
    overflow: hidden;
}

.single-features .project-title-msp {
    padding: 1rem 0;
    color: #000;
    font-size: 1.2rem;
}

/* .single-features:hover .project-title-msp {
      background: #F4B835;
      color: #000;
  } */

.single-features img {
    width: 100%;
    height: auto;
    -webkit-transition: 0.9s;
    transition: 0.9s;
    aspect-ratio: 1/1;
    border-radius: 0;
    border-radius: 0.375rem 0.375rem 0 0;
}

/* .single-features:hover img {
      transform: scale(1.1);
  } */

.single-features .icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    color: #f4b835;
    font-size: 30px;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: inline-block;
    border: 1.3px solid #f4b835;
}

.single-features h3 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.single-features a {
    color: #f4b835;
    font-size: 15px;
}

/* 
  .single-features a:hover,
  .single-features a:focus {
      letter-spacing: 2px;
  }
  
  .single-features:hover,
  .single-features:focus {
      border-color: #ebebeb;
  }
  
  .single-features:hover .icon,
  .single-features:focus .icon {
      background-color: #F4B835;
      color: #ffffff;
  } */

.bg-g {
    background-image: url("../assest/img/kenyalanding/banner/event.png");
    padding-top: 5%;
    padding-bottom: 5%;
    text-align: center;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.bg-event {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url("../assest/img/kenyalanding/banner/about.png");
}

.bg-event h2 {
    color: #ffcc00 !important;
}

.bg-event img {
    /* background: #fff; */
    /* padding: 1rem; */
    /* height: 100px;
      object-fit: contain; */
    /* width: 100px; */
}

.bg-g:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.623);
}

.bg-event:before {
    background: rgba(0, 0, 0, 0.623);
}

.hero-content h1,
.hero-content h2 {
    color: #ffffff;
    font-size: 42px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 0;
}

.hero-content h2 {
    color: #ffffff;
    font-size: 3rem;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 0;
    /* background: -webkit-linear-gradient(135deg, #f4b936, #243058);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
}

.hero-content h6 {
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 0;
    background: -webkit-linear-gradient(135deg, #f4b936, #243058);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.hero-content p {
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;

    margin-bottom: 0;
}

.hero-content p i {
    color: #f4b835;
}

.cursive-font {
    /* font-family: "heading";
      word-spacing: 4px; */
    font-weight: bold;
    /* font-size: 1.4rem;
      letter-spacing: 3px; */
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 20px;
    }

    .hero-content p {
        font-size: 16px;
    }
}

/* single deals */
.popup-with-form {
    cursor: pointer;
}

.services-area {
    padding-bottom: 70px;
}

.single-services {
    /* margin-bottom: 5px; */
    /* background-image: linear-gradient(180deg, #bf9e60, #e7de9c); */
    text-align: left;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 0.375rem;
    border: 1px solid #e3e3e3;
    padding: 1rem;
    /* display: flex; */
    align-items: center;
}

.single-services .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    /* color: #f4b835; */
    /* font-size: 35px; */
    border-radius: 3px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: inline-block;
    /* border: 1.3px solid #f4b835; */
    background: #fff;
    margin-right: 1rem;
}

.single-services h3 {
    /* margin-top: 25px; */
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    /* text-transform: uppercase; */
}

.single-services .bar {
    position: relative;
    margin-top: 12px;
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 75px;
    height: 3px;
    background: #fff;
}

.single-services a {
    color: #f4b835;
    font-size: 15px;
}

.single-services p {
    font-size: 20px;
    font-weight: 400;
}

.single-services a:hover,
.single-services a:focus {
    letter-spacing: 2px;
}

.single-services:hover,
.single-services:focus {
    border-color: #ebebeb;
}

/* .single-services:hover .icon,
  .single-services:focus .icon {
      background-color: #02a6a4;
      color: #ffffff;
      border-radius: 50%;
  } */
@media (max-width: 568px) {
    .single-services {
        margin-bottom: 1rem;
    }
}

/*================================================
  About CSS
  =================================================*/
.about-image {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.about-image .back-img1 {
    position: absolute;
    z-index: -1;
    right: 10px;
    top: -30px;
}

.about-image .back-img2 {
    position: absolute;
    z-index: -1;
    left: 10px;
    bottom: -30px;
}

.about-image .circle-img {
    position: absolute;
    bottom: -50px;
    right: -4px;
    z-index: 2;
    border-radius: 50%;
    border: 5px solid #f4b835;
}

.about-content h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
}

.about-content p {
    margin-bottom: 0;
}

.about-content .about-inner .single-inner-box {
    position: relative;
    margin-top: 30px;
    padding-left: 85px;
}

.about-content .about-inner .single-inner-box .icon {
    position: absolute;
    left: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    top: 0;
    color: #f4b835;
    font-size: 35px;
    border: 1.3px solid #f4b835;
    width: 65px;
    text-align: center;
    height: 70px;
    line-height: 68px;
    border-radius: 3px;
}

.about-content .about-inner .single-inner-box h4 {
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.about-content .about-inner .single-inner-box:hover .icon,
.about-content .about-inner .single-inner-box:focus .icon {
    color: #ffffff;
    background-color: #f4b835;
}

/*================================================
  Property Details CSS
  =================================================*/
.property-details-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.property-details-area::before {
    position: absolute;
    right: -80%;
    background: #f4b835;
    content: "";
    top: 0;
    width: 260%;
    height: 585%;
    z-index: 22;
    -webkit-transform: skewY(108deg);
    transform: skewY(108deg);
}

.video-image {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    /* background-image: url("../assest/img/kenyalanding/v"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.video-image img {
    display: none;
}

.video-image::before {
    position: absolute;
    right: 0;
    background: #000000;
    content: "";
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.39;
    z-index: -1;
}

.video-image .video-btn {
    display: inline-block;
    width: 90px;
    height: 90px;
    text-align: center;
    line-height: 90px;
    background: rgba(255, 255, 255, 0.88);
    border-radius: 50%;
    font-size: 30px;
    color: #f4b835;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 65px;
    margin: 0 auto;
}

.video-image .video-btn::after {
    z-index: -1;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
    -webkit-animation: ripple 1.6s ease-out infinite;
    animation: ripple 1.6s ease-out infinite;
    opacity: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
}

@-webkit-keyframes ripple {

    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

@keyframes ripple {

    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

.property-details-info {
    position: relative;
    z-index: 25;
}

.property-details-info .property-details {
    max-width: 540px;
}

.property-details-info .property-details h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #ffffff;
}

.property-details-info .property-details ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.property-details-info .property-details ul li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    color: #ffffff;
    font-size: 16px;
    margin-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
}

.property-details-info .property-details ul li span {
    font-weight: 600;
}

.property-details-info .additional-details {
    margin-top: 35px;
    max-width: 540px;
}

.property-details-info .additional-details h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #ffffff;
}

.property-details-info .additional-details ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.property-details-info .additional-details ul li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    color: #ffffff;
    font-size: 16px;
    margin-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
}

.property-details-info .additional-details ul li span {
    font-weight: 600;
}

.property-details-area-two {
    position: relative;
    z-index: 1;
}

.property-details-area-two::before {
    position: absolute;
    right: 0;
    background: #f4b835;
    content: "";
    top: 0;
    width: 53%;
    height: 100%;
    z-index: 22;
}

/*================================================
  Photo Gallery CSS
  =================================================*/
.photo-gallery-item {
    overflow: hidden;
    position: relative;
}

.photo-gallery-item a img {
    -webkit-transition: 0.9s;
    transition: 0.9s;
}

.photo-gallery-item:hover a img,
.photo-gallery-item:focus a img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.contact-info ul {
    padding: 0;
}

.contact-info li {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 100%;
    color: #3c3c3c;
    position: relative;
    font-size: 15px;
    margin-top: 10px;
    /* padding-left: 39px; */
    padding-top: 5px;
    list-style: none;

    & a {
        text-decoration: none;
        color: #ffcc00;
    }

    & i {
        color: #f4b835;
        margin-right: 10px;
    }
}

.social {
    padding: 0;
    list-style-type: none;
    margin: 0;

    & li {
        display: inline-block;
        margin-right: 10px;

        & a {
            /* color: #ffcc00; */

            background-color: #000;
            border-radius: 50%;
            padding: 10px;
            line-height: 0;
            position: relative;

            & i {
                color: #f4b835;
                font-size: 20px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

/* form */
#MSPContactForm {
    background-color: rgba(222, 222, 222, 0.56);
    /* padding: 5%; */
    border-radius: 10px;
}

#MSPContactForm .form-group label {
    font-size: 10px !important;
}

/* footer */
.footer-area {
    text-align: center;
    background: #000000;
    padding: 15px 0;
}

.footer-area p {
    color: #ffffff;
}

.footer-area p a {
    display: inline-block;
    color: #ffffff;
}

.footer-area p a:hover,
.footer-area p a:focus {
    color: #f4b835;
}

/* .iframe {
      width: 100% ;
      height: 450px;
  }
  @media (max-width:768px) {
      
  .iframe {
      width: 100% ;
      height: 600px;
  }
  } */
.developers-card {
    position: static;
    border-radius: 0.375rem;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    height: 100%;
}

.developers-card img {
    min-height: 200px;
    object-fit: cover;
}

.developers-card .card-constant {
    padding: 1rem;
    background: #ffcc00;
    display: flex;
    justify-content: center;
    align-items: center;
}

.developers-card .card-constant h1 {
    font-size: 2rem;
    margin-bottom: 0;
    margin-right: 1rem;
}

.developers-card .card-constant p {
    font-size: 1.3rem;
    margin-bottom: 0;
}

.form-bg {
    background: #dedede8f !important;
}

.iframe {
    width: 100%;
    height: 190px;
    /* border-radius: 0.375rem; */
    text-align: center;
}

.ipe-form-modal {
    overflow: hidden;
    border-radius: 0 0 10px 10px;
}

.ipe-form-modal .visitorformbg-ipe .row {
    flex-direction: column;
}

.ipe-form-modal .visitorformbg-ipe .row .col-sm-3 {
    width: 100% !important;
}

.ipe-form-modal .visitorformbg-ipe .row small {
    margin-bottom: 10px !important;
    margin-top: 5px;
}

.iframe-model {
    width: 100%;
    height: 600px;
    border-radius: 0 0 0.375rem 0.375rem;
    background-color: #dedede8f;
}

.section-two-right .aos-init {
    margin-bottom: -5px;
}

.heading-style {
    position: relative;
    padding-bottom: 10px;
}

.heading-style::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 4px;
    background: #ffcc00;
    border-radius: 0.375rem;
}

footer img {
    width: 120px;
}

footer img:first-child {
    width: 160px;
}

footer a {
    text-decoration: none;
    color: #000;
}

.footer-contact-links {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-contact-links a {
    display: flex;
    color: #000;
    margin-bottom: 10px;
    align-items: center;
    width: fit-content;
}

.footer-contact-links a i {
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
    background: #ffcc00;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 1.3rem;
}

.footer-text {
    margin-bottom: 0;
    font-size: 0.7rem;
    text-align: center;
    margin-top: 10px;
}

footer .fa-location-dot {
    color: #ff0000;
}

.mobile-banner {
    display: none;
}

.seven-cards-box {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.three-card-box .seven-card {
    width: 31%;
}

@media (max-width: 992px) {
    .iframe-model {
        width: 100%;
        height: 470px;

        overflow-y: auto;
    }
}

@media (max-width: 328px) {
    .iframe-model {
        width: 100%;
        height: 470px;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .iframe {
        width: 100%;
        height: 470px;
    }
}

@media (max-width: 768px) {
    .iframe {
        width: 100%;
        height: 470px;
    }

    .navbar .btn-register {
        display: none;
    }

    .main-logo img {
        width: 80px;
    }

    .main-logo p {
        font-size: 1rem;
    }

    .banner {
        background: transparent;
        height: auto;
    }

    .mobile-banner {
        /* border-radius: .373rem; */
        /* margin-top: 1rem; */
        padding: 0;
        display: block;
    }

    .developers-card .card-constant {
        flex-direction: column;
    }

    .developers-card .card-constant p {
        font-size: 1.4rem;
    }
}

@media (max-width: 568px) {
    .seven-cards-box {
        overflow-x: auto;
    }

    .seven-cards-box .seven-card {
        min-width: 40%;
    }
}

@media (max-width: 365px) {
    .iframe {
        width: 100%;
        /* height: 660px; */
    }
}

@media (max-width: 335px) {
    .iframe {
        width: 100%;
        /* height: 670px; */
    }
}

.model-cross-visitor,
.model-cross-visitor iframe {
    background: #ececec;
}

.model-cross-visitor .btn-close {
    -webkit-filter: none !important;
    filter: none !important;
    opacity: 1 !important;
}

.footer-area a {
    text-decoration: none;
}

.swiper {
    width: 100%;
    height: 100%;
}

.mySwiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.mySwiper img {
    height: 100px;
    object-fit: contain;
}

.swiper-pagination-bullet-active {
    background: #000;
}

.sponsor-logo-1 {
    height: 200px;
    object-fit: contain;
}

.sponsor-logo-2 {
    object-fit: contain;
    margin-top: 1rem;
    height: 120px;
}

/* new dubai page */
/* .dubai-background {
    background-color: #050a30;
    color: #fff;
    padding: 5px 10px;
}

.dubai-content .march {
    border-right: 4px solid yellow;

}

.dubai-content h2 {
    font-weight: 800 !important;
}

.dubai-content-regal h2,
h3,
h4 {
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* 
.dubai-landing {
    background-color: #001144;
    color: white;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    font-weight: bold;
}

.dubai-landing .content-left-dubai {
    font-size: 60px;
    text-align: center;
    border-right: 4px solid yellow;
    padding-right: 20px;
}

.dubai-landing .content-left-dubai div {
    font-weight: 900;
}

.dubai-landing .content-right-dubai {
    padding-left: 20px;
    font-size: 24px;
}

.dubai-landing .content-right-dubai h2,
p {
    margin: 0;
    font-size: 40px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dubai-landing .content-right-dubai p {
    margin: 5px 0;
    font-size: 30px;
}

.dubai-landing .details {
    font-size: 22px !important;
    margin-top: 5px;
}

.highlight {
    color: yellow;
} */

@media (max-width: 568px) {
    .thank-you-wrapper>.container .logo-img {
        max-width: 250px;
    }

    .kenya-landing-page {
        padding-bottom: 4rem;
    }
}