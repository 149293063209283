.footer-contact-links {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer-contact-links a {
  display: flex;
  color: var(--black-color);
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
}
.footer-contact-links a svg {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  background: var(--theme-color);
  color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.footer-text{
    margin-bottom: 0;
    font-size: .7rem;
    text-align: center;
    margin-top: 10px;
}